
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      items: [
        {
          rule: "Course must have special stipend flag enabled & duration must be 2 months or more",
          count: 20,
        },
        {
          rule: "Completed Batches",
          count: 20,
        },
        {
          rule: "Must have bank account number or mobile banking",
          count: 20,
        },
        {
          rule: "Must have 80% attendance",
          count: 20,
        },
        {
          rule: "Ethnic Minority",
          count: 20,
        },
        {
          rule: "Physically Disabled",
          count: 20,
        },
        {
          rule: "Female of 54 district",
          count: 20,
        },
        {
          rule: "Poor and Has permanent address and Has income certificate",
          count: 20,
        },
        {
          rule: "Total",
          count: 20,
        },
      ],
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "Batch",
          key: "batch",
          sortable: true,
        },
        {
          name: "Bank Account & 80% Attendance",
          key: "bank_account_attendence",
          sortable: true,
        },
        {
          name: "Ethnic (A)",
          key: "ethnic",
          sortable: true,
        },
        {
          name: "Challenged (B)",
          key: "challenged",
          sortable: true,
        },
        {
          name: "Female of 54 district (C)",
          key: "female_district_basis",
          sortable: true,
        },
        {
          name: "Poor (D)",
          key: "poor",
          sortable: true,
        },
        {
          name: "Total (A+B+C+D)",
          key: "total",
          sortable: true,
        },
      ],
      tableData: [
        {
          sl: 1,
          name: "ABC",
          course: "A.B.C Bangladesh-Turkish Technical School",
          batch: "Nazirhat Municipality ,Fatickchari ",
          bank_account_attendence: "Nazirhat Municipality ,Fatickchari ",
          ethnic: "0312552697 ",
          challenged: "abcbdtktschool@yahoo.com",
          female_district_basis: "www.doulatpurabchighschool.edu.bd ",
          poor: "www.doulatpurabchighschool.edu.bd ",
          total: "www.doulatpurabchighschool.edu.bd ",
        },
        {
          sl: 2,
          name: "cbh",
          course: "A.B.C Bangladesh-Turkish Technical School",
          batch: "Nazirhat Municipality ,Fatickchari ",
          bank_account_attendence: "Nazirhat Municipality ,Fatickchari ",
          ethnic: "0312552697 ",
          challenged: "abcbdtktschool@yahoo.com",
          female_district_basis: "www.doulatpurabchighschool.edu.bd ",
          poor: "www.doulatpurabchighschool.edu.bd ",
          total: "www.doulatpurabchighschool.edu.bd ",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
